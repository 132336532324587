import { RouteRecordRaw } from 'vue-router';

export const userProfileRoutesNames = {
  userProfile: 'UserProfile'
};

export const userProfileRoutes: Array<RouteRecordRaw> = [
  {
    path: 'user-profile',
    name: userProfileRoutesNames.userProfile,
    component: () => import ('./index.vue'),
  },
];