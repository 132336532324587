import { http } from '@/services';
import { INotificationForm, INotificationRes, TIndexedObject } from '@/types';

class NotificationsService {

  createNotification(payload: INotificationForm) {
    return http.post<INotificationRes>('notifications', payload);
  }

  updateNotification(id: number | string, payload: INotificationForm) {
    return http.patch<INotificationRes>(`notifications/${id}`, payload);
  }

  fetchNotifications(params?: TIndexedObject) {
    return http.get<INotificationRes[]>('notifications', params);
  }

  fetchNotification(id: number | string, params?: TIndexedObject) {
    return http.get<INotificationRes[]>(`notifications/${id}`, params);
  }

  deleteNotification(id: number) {
    return http.delete<INotificationRes>(`notifications/${id}`);
  }

  fetchNotificationsCount(params?: TIndexedObject) {
    return http.get<{count: number}>('notifications/count', params);
  }

  downloadNotifications(order?: Array<string[]>) {
    return http.get<any>('notifications/download', {
      "params": {
        "filter": {
          order: order?.length ? order : [ ["createdAt", "DESC"] ],
        },
        "fields": [
          {
            "name": "Description",
            "source": "description"
          },
          {
            "name": "External link",
            "source": "externalLink"
          },
          {
            "name": "Publication date",
            "source": "createdAt"
          }
        ]
      }
    });
  }

}

export const notificationsService = new NotificationsService();