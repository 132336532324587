import { RouteRecordRaw } from 'vue-router';

export const childrenRoutesNames = { 
  children: 'Children', 
  addChild: 'AddChild', 
  updateChild: 'UpdateChild', 
  childrenView : 'ChildrenView'
};

export const childrenRoutes: Array<RouteRecordRaw> = [
  {
    path: 'children',
    name: childrenRoutesNames.children,
    redirect: { name: childrenRoutesNames.childrenView },
    component: () => import('./index.vue'),
    children: [
      {
        path: 'children-view',
        name: childrenRoutesNames.childrenView,
        component: () => import('./ChildrenView.vue'),
      },
      {
        path: 'add-child',
        name: childrenRoutesNames.addChild,
        component: () => import('./AddChild.vue'),
        meta: {
          breadCrumb: 'Add child'
        }
      },
      {
        path: 'update-child',
        name: childrenRoutesNames.updateChild,
        component: () => import('./UpdateChild.vue'),
        meta: {
          breadCrumb: 'Update child'
        }
      },
    ]
  },
];