import { AxiosError, AxiosResponse } from 'axios';
import { router, routesNames } from '@/router';

const responseInterceptor = (response: AxiosResponse): Promise<AxiosResponse> => {
  return response.data;
};

const errorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  const { response } = error;

  if (response?.data.statusCode === 401) {

    router.push({ name: routesNames.login });
  }

  return Promise.reject(error);
};

export {
  responseInterceptor,
  errorInterceptor
};