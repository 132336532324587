import { AxiosError, AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';

const requestInterceptor = async (requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  // For ensuring to send valid tokens 
  const session = await Auth.currentSession();
  requestConfig.headers.Authorization = `Bearer ${(session.getIdToken() as any).jwtToken}`;

  return requestConfig;
};

const requestErrorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

export {
  requestInterceptor,
  requestErrorInterceptor
};