import { RouteRecordRaw } from 'vue-router';

export const playgroundRoutesNames = { 
  notifications: 'Notifications',
  tabs: 'Tabs',
  toggles: 'Toggles',
  sidebar: 'Sidebar',
  buttons: 'Buttons',
  tables: 'Tables',
  tooltips: 'Tooltips',
  inputs: 'Inputs',
  datepickers: 'DatePickers',
  selects: 'Selects',
  checkbox: 'Checkbox',
  textarea: 'Textarea',
  badge: 'Badge'
};

export const playgroundRoutes: Array<RouteRecordRaw> = [
  {
    path: 'notifications',
    name: playgroundRoutesNames.notifications,
    component: () => import('./components/Notifications.vue')
  },
  {
    path: 'tabs',
    name: playgroundRoutesNames.tabs,
    component: () => import('./components/Tabs.vue')
  },
  {
    path: 'toggles',
    name: playgroundRoutesNames.toggles,
    component: () => import('./components/Toggles.vue')
  },
  {
    path: 'sidebar',
    name: playgroundRoutesNames.sidebar,
    component: () => import('./components/Sidebar.vue')
  },
  {
    path: 'buttons',
    name: playgroundRoutesNames.buttons,
    component: () => import('./components/Buttons.vue')
  },
  {
    path: 'tables',
    name: playgroundRoutesNames.tables,
    component: () => import('./components/Tables.vue')
  },
  {
    path: 'tooltips',
    name: playgroundRoutesNames.tooltips,
    component: () => import('./components/Tooltips.vue')
  },
  {
    path: 'inputs',
    name: playgroundRoutesNames.inputs,
    component: () => import('./components/Inputs.vue')
  },
  {
    path: 'datepickers',
    name: playgroundRoutesNames.datepickers,
    component: () => import('./components/DatePickers.vue')
  },
  {
    path: 'selects',
    name: playgroundRoutesNames.selects,
    component: () => import('./components/Selects.vue')
  },
  {
    path: 'checkbox',
    name: playgroundRoutesNames.checkbox,
    component: () => import('./components/Checkbox.vue')
  },
  {
    path: 'textarea',
    name: playgroundRoutesNames.textarea,
    component: () => import('./components/Textarea.vue')
  },
  {
    path: 'badge',
    name: playgroundRoutesNames.badge,
    component: () => import('./components/Badge.vue')
  }
];