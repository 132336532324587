
import { defineComponent, computed } from 'vue';

import { generateInitials } from '@/core/helper-functions';

export default defineComponent({
  name: 'AppUserAvatar',

  props: {
    username: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const name = computed<string>(() => generateInitials(props.username));

    return { name };
  }
});
