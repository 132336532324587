<template>
  <div class="w-full bg-white">
    <!-- D E S K T O P   N A V B A R -->
    <FamilyPortalDesktopNavbar
      :items="links"
      :user-full-name="userFullName"
      class="hidden md:flex"
      @log-out="logOut"
    />

    <!-- M O B I L E -->
    <FamilyPortalSidebar
      :items="links"
      :user-full-name="userFullName"
      class="md:hidden block"
      @log-out="logOut"
    />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue';
  import { useRouter } from 'vue-router';

  import FamilyPortalDesktopNavbar from '@/components/navbars/FamilyPortalDesktopNavbar.vue';
  import FamilyPortalSidebar from '@/components/navbars/FamilyPortalSidebar.vue';

  import { INavbarItem } from '@/types';
  import { routesNames } from '@/router';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'AppFamilyPortalNavbar',

    components: { FamilyPortalDesktopNavbar, FamilyPortalSidebar },

    setup() {
      const router = useRouter();
      const links: INavbarItem[] = [
        { name: 'Home', pathName: 'Home' },
        {
          name: 'Me', pathName: 'UserOptions', sublinks: [
            { name: 'My Essentials', pathName: 'MyEssentials' },
            { name: 'Explore Activities', pathName: 'ExploreActivities' },
            { name: 'My Goals', pathName: 'MyGoals' },
            { name: 'My Health', pathName: 'MyHealth', disabled: true, hidden: true },
          ]
        },
        { name: 'Children', pathName: 'Children' },
        { name: 'Contact', externalLink: 'https://forms.gle/tKYZ7v12wwXhHkX68'}
      ];

      let userFullName = computed<string>(() => vuex.user?.attributes.name + ' ' + vuex.user?.attributes.family_name);

      function logOut() {
        router.push({ name: routesNames.login });
      }

      return {
        links,
        vuex,
        userFullName,

        logOut
      };
    }
  });
</script>

<style lang="scss" scoped>
  .router-link-active {
    &:not(.mobile-link) {
      @apply relative;
      &::after {
        @apply bg-primary w-full h-3 absolute left-0 bottom-0;
        content: "";
      }
    }

    &.mobile-link {
      @apply text-primary;
    }
  }
</style>

