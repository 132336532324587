
  import { defineComponent, ref, PropType } from 'vue';

  import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { INavbarItem } from '@/types';
  import { router, routesNames } from '@/router';

  export default defineComponent({
    name: 'FamilyPortalSidebar',

    components: { AppUserAvatar, AppIcon },

    props: {
      items: {
        type: Array as PropType<INavbarItem[]>,
        required: true
      },

      userFullName: {
        type: String,
        required: true
      }
    },

    emits: ['log-out', 'user-profile'],

    setup() {
      let isOpenMenu = ref<boolean>(false);
      let isOpenSublinkMenu = ref<boolean>(false);

      function handleOpenNav() {
        if (isOpenMenu.value) {
          document.body.style.overflow = 'auto';
          isOpenMenu.value = false;
          isOpenSublinkMenu.value = false;
        } else {
          document.body.style.overflow = 'hidden';
          isOpenMenu.value = true;
        }
      }

      function toggleSubmenu() { isOpenSublinkMenu.value = !isOpenSublinkMenu.value; }

      function onUserProfile () {
        router.push({ name: routesNames.userProfile });
        handleOpenNav();
    }

      return {
        isOpenMenu,
        isOpenSublinkMenu,
        router,
        routesNames,

        handleOpenNav,
        toggleSubmenu,
        onUserProfile
      };
    }
  });
