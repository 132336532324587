
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Playground',

  setup() {
    const links = [
      'Buttons',
      'Notifications',
      'Tabs',
      'Tables',
      'Inputs',
      // 'Colors',
      'Toggles',
      'Sidebar',
      'Tooltips',
      'DatePickers',
      'Selects',
      'Checkbox',
      'Textarea',
      'Badge'
    ];

    return { links };
  }
});
