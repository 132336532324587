import { RouteRecordRaw } from 'vue-router';

export const homeRoutesNames = { home: 'Home' };

export const homeRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: homeRoutesNames.home,
    component: () => import('./Home.vue')
  }
];