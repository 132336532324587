import { TIndexedObject } from '@/types';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';
/**
 * @param {string} fieldName error field name
 * @param {[key: string]: string} errorMessages object with error messages
 *
 * @returns {void}
 *
 */
export const clearErrorField = (fieldName: string | string[], errorMessages: TIndexedObject): void => {

  if (typeof fieldName === 'string') {
    errorMessages[fieldName] = '';
  } else {
    fieldName.forEach((n: string) => {
      errorMessages[n] = '';
    });
  }
};

/**
 * @param {string | number} val phone number value
 *
 * @returns {string} formatted phone number value
 *
 */
export const formatPhoneNumber = (val: string | number): string => {

  return val.toString().split('').filter((s: string) => s.trim() && /[0-9 +]/.test(s)).join('');
};

/**
 * @param {any} validationErrors vuelidate array with errors
 * @param {[key: string]: string} storedErrorMessages object with error messages
 *
 * @returns {void}
 *
 */
export const handleSetErrors = (validationErrors: any[], storedErrorMessages: TIndexedObject): void => {

  if (!validationErrors.length) return;

  const errors = validationErrors;

  errors.forEach((error: any) => {
    const key: string = error.$property;
    storedErrorMessages[key] = error.$message;
  });
};

/**
 * @param {string} name name of stored error key
 * @param {any} v vuelidate instance
 * @param {[key: string]: string} storedErrorMessages object with error messages
 *
 * @returns {void}
 *
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateField = (name: string, v: any, storedErrorMessages: TIndexedObject): void => {

  const field = v.value[name];

  if (field.$error) {
    storedErrorMessages[name] = field.$errors[0].$message;
  }
};

/** Creates initials from a name. For a single word uses the first two letters,
 *  for 2 words uses the first letter of both words,
 *  for any text with more than 2 words uses the first letter of the first and last words.
 * @param {string} name name of stored error key
 * 
 * @returns {string}
 */
export const generateInitials = (text: string): string => {

  if (text === null || text === undefined) return '';

  let initials = '';
  const nameParts = text.trim().split(' ');

  if (nameParts.length < 2)
    initials = nameParts[0].charAt(0) + nameParts[0].charAt(1);

  if (nameParts.length === 2)
    initials = nameParts[0].charAt(0) + nameParts[1].charAt(0);

  if (nameParts.length > 2)
    initials = nameParts[0].charAt(0) + nameParts[nameParts.length - 1].charAt(0);

  return initials.toUpperCase();
};

/** Capitalize first letter of string
 * @param {string} name income string
 * 
 * @returns {string}
 */
export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getNestedProp = (obj: TIndexedObject, key: string): TIndexedObject => {
  const arr: Array<string> = key.split('.');

  while (arr.length && obj) {
    const shift = arr.shift();
    if (shift) obj = obj[shift];
  }

  return obj;
};

export const sortArrayByKey = (key: string, order = 'asc') => {
  return (a: TIndexedObject, b: TIndexedObject): number => {
    const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

    const comparison = (varA > varB) ? 1 : (varA < varB) ? -1 : 0;

    return (order === 'desc') ? (comparison * -1) : comparison;
  };
};

export const getChangedData = (originalData: TIndexedObject, changedData: TIndexedObject): TIndexedObject => {
  const keys = Object.keys(originalData);
  const data = {} as TIndexedObject;

  keys.forEach((el: string) => {

    if (!isEqual(changedData[el], originalData[el])) {
      data[el] = changedData[el];
    }
  });

  return data;
};

export const validateLength = (maxLength: number) => (value: string): boolean => {

  if (value && value.length < maxLength) {
    return false;
  }

  return true;
};

export const formatStringPhoneNumber = (phoneNumberString: string): string | null => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const containsUppercase = (value: string): boolean => /[A-Z]/.test(value);
export const containsNumber = (value: string): boolean => /[0-9]/.test(value);

export const downloadHelper = (res: Blob, fileName: string, fileExtention: string, type = 'application/octet-stream'): void => {
  const blob = new Blob([res], { type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.${fileExtention}`;
  link.click();
};

export const validDate = (date: string, format: string): boolean => {

  return dayjs(date, format).format(format) === date;
};