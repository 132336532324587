<template>
  <div class="flex items-center justify-center bg-primary text-white rounded-half leading-normal relative">
    <div 
      v-if="loading" 
      class="absolute bg-white opacity-60 top-0 left-0 h-full w-full flex items-center justify-center z-1000"
    >
      <div class="loader loader__small" />
    </div>
    <span v-else>
      {{ name }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import { generateInitials } from '@/core/helper-functions';

export default defineComponent({
  name: 'AppUserAvatar',

  props: {
    username: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const name = computed<string>(() => generateInitials(props.username));

    return { name };
  }
});
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/scss/components/_loader";
</style>