import { Auth } from 'aws-amplify';
import { useToast } from "vue-toastification";
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { vuex } from '@/store';
import { formatPhoneNumber } from '@/core/helper-functions';
import { ISignUpForm, ILogInForm, IConfirmForgotPasswordForm } from '@/types';

class AuthService {

  /** S I G N  U P
   * 
   * @param {ISignUpForm} payload form of user information
   *
   * @returns {Promise<any>}
   */
  signUp = async ( payload: ISignUpForm): Promise<any> => {
    vuex.setLoading(true);

    try {
        const res = await Auth.signUp({
          username: payload.email,
          password: payload.password,
          attributes: {
            email: payload.email,
            name: payload.firstName,
            family_name: payload.lastName,
            phone_number: formatPhoneNumber(payload.phone),
            'custom:zip_code': payload.zipCode,
            'custom:partner': 'CEB' // TODO add dynamic
          }
        });

      return res;

    } finally { vuex.setLoading(false); }
  };

  /** S I G N  O U T
   *
   * @returns {Promise<void>}
   */
  signOut = async (): Promise<any> => {
    vuex.setLoading(true);

    try {
      if (vuex.user) {
        await vuex.user.revokeTokens();
      }

      await Auth.signOut();

    } finally { vuex.setLoading(false); }
  };

  /** C O N F I R M  S I G N  U P
   * 
   * @param {string} userEmail email for verification
   * @param {string} verificationCode verification code
   *
   * @returns {Promise<void>}
   */
  confirmSignUp = async (userEmail: string, verificationCode: string): Promise<void> => {
    vuex.setLoading(true);

    try {
      const res = await Auth.confirmSignUp(userEmail, verificationCode);

      return res;

    } finally { vuex.setLoading(false); }
  };

  /** R E S E N D  S I G N  U P
   * 
   * @param {string} userEmail email where will be sended confirmation code
   *
   * @returns {Promise<void>}
   */
  resendSignUp = async (userEmail: string): Promise<void> => {
    vuex.setLoading(true);

    try {
      const res = await Auth.resendSignUp(userEmail);
      const toast = useToast();
      toast.success('Verification code has been resend');
      
      return res;

    } finally { vuex.setLoading(false); }
  };

  /** L O G  I N
   * 
   * @param {ILogInForm} payload Login form information
   *
   * @returns {Promise<CognitoUserInterface>} Cognito User information
   */
  logIn = async (payload: ILogInForm): Promise<CognitoUserInterface> => {
    vuex.setLoading(true);

    try {
      const res = await Auth.signIn({
        username: payload.email,
        password: payload.password
      });

      return res;

    } finally { vuex.setLoading(false); }
  };

  /** F O R G O T   P A S S W O R D 
   * 
   * @param {string} email user email where will be sended resetting code
   *
   * @returns {Promise<void>}
   */
  forgotPassword = async (email: string): Promise<void> => {
    vuex.setLoading(true);

    try {
      const res = await Auth.forgotPassword(email);

      return res;

    } finally { vuex.setLoading(false); }
  };

  /** F O R G O T  P A S S W O R D  S U B M I T
   * 
   * @param {IConfirmForgotPasswordForm} payload form information for creating new password
   *
   * @returns {Promise<void>}
   */
  forgotPasswordSubmit = async ({ userName, code, password }: IConfirmForgotPasswordForm): Promise<void> => {
    vuex.setLoading(true);

    try {
      const res = await Auth.forgotPasswordSubmit(userName, code, password);

      return res;

    } finally { vuex.setLoading(false); }
  };

  /** C H E C K  V A L I D  S E S S I O N
   * 
   * @returns {Promise<CognitoUserInterface | null>}
   */
  getCurrentSession = async (): Promise<boolean> => {

    try {
      const session = await Auth.currentSession();
      return session.isValid();
    }
    catch(err) { 
      throw null;
    }
  };

  /** H A N D L E  A U T H E N T I C A T E D  U S E R
   * 
   * @returns {Promise<CognitoUserInterface | null>}
   */
  getAuthenticatedUser = async (bypassCache = false): Promise<CognitoUserInterface | null> => {
    let result = null;

    vuex.setLoading(true);
    try {

      if (await this.getCurrentSession()) {
        const user = await Auth.currentAuthenticatedUser({bypassCache});
        vuex.setUser(user);

        result = user;
      }

    }
    catch(err) { 
      result = null;
      vuex.setUser(null);
    } 
    finally { vuex.setLoading(false); }
    
    return result;
  };

  changePassword = async (oldPassword: string, newPassword: string) => {
    vuex.setLoading(true);

    try {

      const user = await Auth.currentAuthenticatedUser();

      return await Auth.changePassword(user, oldPassword, newPassword);

    } finally { vuex.setLoading(false); }
  }

}

export const authService = new AuthService();
