
  import { defineComponent, ref, onBeforeMount } from 'vue';
  import { authService } from '@/services';

  export default defineComponent({
    name: 'Auth',

    setup() {
      let partnerName = ref<string>('CEB'); // TODO should be dynamic

      onBeforeMount(async () => {
        await authService.signOut();
      });

      return { partnerName };
    }
  });
