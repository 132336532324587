
import { defineComponent } from 'vue';

import AppFamilyPortalNavbar from '@/components/navbars/AppFamilyPortalNavbar.vue';

export default defineComponent({
  name: 'Default',

  components: { AppFamilyPortalNavbar },

});
