<template>
  <div class="flex md:bg-white">
    <!-- S I D E B A R -->
    <div class="hidden h-screen md:block relative top-0 lef-0 bottom-0 h-screen bg-primary flex flex-col mb-0">
      <h1 class="text-white text-2xl p-20 border-b-2 border-white">Playground</h1>
      <ul class="links flex flex-col overflow-y-auto">
        <router-link 
          v-for="(link, index) of links" 
          :key="index"
          :to="{ name: link }"
        >
          <li class="py-10 px-20 text-white hover:text-primary hover:bg-white mb-5 text-md">{{ link }}</li>
        </router-link>
      </ul>
    </div>

    <!-- V I E W S -->
    <div class="views max-h-screen p-10 flex flex-col">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Playground',

  setup() {
    const links = [
      'Buttons',
      'Notifications',
      'Tabs',
      'Tables',
      'Inputs',
      // 'Colors',
      'Toggles',
      'Sidebar',
      'Tooltips',
      'DatePickers',
      'Selects',
      'Checkbox',
      'Textarea',
      'Badge'
    ];

    return { links };
  }
});
</script>

<style scoped lang="scss">
.router-link-exact-active {
  
  li {
    @apply bg-white text-primary;
  }
}

.links {
  height: 88%;
}

@media ( min-width: 769px) {
  .views {
    width: calc(100% - 177px);
  }
}
</style>