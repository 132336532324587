/* eslint-disable vue/component-definition-name-casing */
import { createApp } from 'vue';
import App from './App.vue';
import { router } from '@/router';
import { store } from './store';
import { toastOptions, Toast, Dropdown, VClosePopper, PortalPlugin, filters } from '@/plugins';
import '@/assets/styles/scss/main.scss';

/* Amplify */
import Amplify from 'aws-amplify';
import { awsAuthConfig } from '@/core/aws-auth.config';
Amplify.configure({
  Auth: awsAuthConfig
});

export const app = createApp(App);
app.use(store).use(router);

/* Toast */
app.use(Toast, toastOptions);

/* Portal */
app.use(PortalPlugin);

/* Filters */
app.use(filters);

/* VTooltip */
app.component('v-dropdown', Dropdown);
app.directive('close-popper', VClosePopper);

/* Element DatePicker */
import { ElDatePicker, ElButton, ElPagination } from 'element-plus';
app.component('v-date-picker', ElDatePicker);
app.component('v-button', ElButton);
app.component('v-pagination', ElPagination);

router.isReady().then(() => {
  app.mount('#app');
});
