<template>
  <div 
    class="admin-sidebar-wrap " 
    :class="[ isCollapsed ? 'w-80' : 'w-280']"
  >
    <!-- C O L L A P S E  I C O N -->
    <AppIcon
      :name="isCollapsed ? 'expand' : 'collapse'"
      size="20"
      class="text-blue-fp-80 absolute top-30 right-30 cursor-pointer" 
      @click="handleCollapse"
    />
    
    <div>
      <!-- A D M I N  I N F O -->
      <div class="p-25 flex flex-col jusify-center items-center min-h-250">
        <div v-if="!isCollapsed" class="flex flex-col items-center w-full">
          <div class="h-80 w-80 flex items-center justify-center bg-grey-fp-20 rounded-half mb-15 mt-76">
            <AppIcon name="user" size="38" class="text-grey-fp-50" />
          </div>
          <p class="text-1xl text-center text-white font-bold truncate w-full">
            Welcome, Admin
          </p>
        </div>
      </div>

      <!-- L I N K S -->
      <ul class="text-blue-fp-80">
        <template v-for="(link, index) of linkItems" :key="index">
          <li v-if="!link.hidden" class="link" :class="{'collapsed' : isCollapsed}">
            <router-link :to="{ name: link.pathName }" class="flex items-center px-30 py-14 cursor-pointer">
              <AppIcon :name="link.icon" size="24" />
              <span v-if="!isCollapsed" class="ml-30 text-xl truncate">
                {{ link.linkName }}
              </span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>

    <!-- L O G  O U T -->
    <div class="pb-50 px-20 mt-20">
      <div class="log-out" :class="{'collapsed': isCollapsed}" @click="handleLogOut">
        <p v-if="!isCollapsed" class="text-md truncate">Log out</p>
        <div class="flex items-center justify-center bg-red-fp-40 rounded-half p-5 max-w-34 cursor-pointer">
          <AppIcon name="logout" size="24" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { routesNames, router } from '@/router';

import AppIcon from '@/components/stateless/AppIcon.vue';

export default defineComponent({
  name: 'AdminSidebar',

  components: { AppIcon },

  setup() {
    const linkItems = [
      { pathName: routesNames.adminFamilies, linkName: 'Families', icon: 'families' },
      { pathName: routesNames.adminNotificationsList, linkName: 'Notifications', icon: 'notifications', },
      { pathName: routesNames.adminActivities, linkName: 'Activities', icon: 'calendar' },
      { pathName: routesNames.adminGoals, linkName: 'Goals', icon: 'goals' },
      { pathName: routesNames.adminSettings, linkName: 'Settings', icon: 'settings' }
    ];

    let isCollapsed = ref<boolean>(false);

    function handleCollapse () {
      isCollapsed.value = !isCollapsed.value;
    }

    function handleLogOut () {
      router.push({ name: routesNames.login });
    }

    return {
      linkItems,
      isCollapsed,

      handleCollapse,
      handleLogOut
    };
  }
});
</script>

<style lang="scss" scoped>
.admin-sidebar-wrap {
  @apply flex flex-col justify-between 
  relative h-full bg-blue-ap-100 rounded-r-5 
  font-lato overflow-y-auto;

  transition: all 0.3s;

  .link {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    transition: all 0.3s;
    position: relative;

    &:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08) ;
    }

    &:hover {
      @apply bg-white text-blue-ap-100 shadow-1;
    }
  }

  .router-link-exact-active, .router-link-active {
    @apply bg-white text-blue-ap-100 shadow-1;
  }

  .log-out {
    @apply flex items-center text-white py-8;
    transition: all 0.3s;

    &:not(.collapsed) {
      @apply rounded-5 cursor-pointer px-20 justify-between bg-blue-ap-100;
      background-color: rgba(255, 255, 255, 0.15);
    }

    &.collapsed {
      @apply justify-center;
    }

    &:hover:not(.icon-logout):not(.collapsed) {
      @apply bg-white text-blue-ap-100;
    }
  }
}
</style>