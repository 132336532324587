import { PluginOptions, POSITION } from "vue-toastification";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import '@/assets/styles/scss/utils/_toastification.scss';

const toastOptions: PluginOptions = {
  position: POSITION.TOP_CENTER,
  hideProgressBar: true,
  closeButton: false
};

export { toastOptions, Toast };