import { createStore } from 'vuex';
// TODO should use this interface but still have a issue on amplify https://github.com/aws-amplify/amplify-js/issues/4927
// import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { useAccessor, actionTree, mutationTree, getterTree } from 'typed-vuex';
interface IState {
  isLoading: boolean
  user: CognitoUserInterface | null
}

export type TRootState = ReturnType<typeof state>

const state = (): IState => ({
  isLoading: false,
  user: null
});

const getters = getterTree(state, {
  isAdmin(state) {
    if (state.user) {
      const { payload } = state.user.signInUserSession.accessToken;
      return payload['cognito:groups'].includes('Admin');
    } else {
      return false;
    }
  }
});

const mutations = mutationTree(state, {

  setLoading(state, newValue: boolean) {
    state.isLoading = newValue;
    if (newValue) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  },

  setUser(state, newValue: CognitoUserInterface | null) {
    state.user = newValue;
  }
});

const actions = actionTree({ state }, {});

const storePattern = {
  state,
  mutations,
  actions,
  getters
};

export const store = createStore(storePattern);
export const vuex = useAccessor(store, storePattern);