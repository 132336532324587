import { http } from '@/services';

import { IGoal, ITaskRes, TIndexedObject, IRequestParams, IPredefinedGoalResponse, IGoalsResponse, ITaskData } from '@/types';
const EXPORT_BLACK_LIST = ['inProgressDerivedGoalsCount', 'completedDerivedGoalsCount', 'tasksCount'];

class MyGoalsService {

  fetchPredefinedGoals(params?: IRequestParams) {
    return http.get<IGoalsResponse>('goals', params);
  }

  fetchGoals(where?: TIndexedObject, limit?: number, offset?: number) {
    return http.get<IGoalsResponse>('goals', {
      params: {
        filter: {
          order: [
            ["markedWithStarAt", "DESC"],
            ["createdAt", "DESC"]
          ],
          include: [
            { 
              model: "task" 
            },
            {
              model: "category"
            }
          ],
          where,
          limit,
          offset
        },
        includeCount: true
      }
    });
  }

  fetchGoal(id: string, params?: IRequestParams) {
    return http.get<IGoal>(`goals/${id}`, params);
  }

  createGoal(payload: any) {
    return http.post<IGoal>('goals', payload);
  }

  deleteGoal(id: string) {
    return http.delete<IGoal>(`goals/${id}`);
  }

  updateGoal(id: string, payload: any) {
    return http.patch<IGoal>(`goals/${id}`, payload);
  }

  updatePredefinedGoal(id: string, payload: Partial<IPredefinedGoalResponse>) {
    return http.patch<IGoal>(`goals/predefined/${id}`, payload);
  }

  updateGoalStatus(status: string, id: string) {
    return http.post<IGoal>(`goals/${id}/${status}`, {
      params: {
        filter: {
          include: [
            { model: "category" }
          ]
        }
      }
    });
  }
  
  updateTaskStatus(status: string, id: number) {
    return http.post<ITaskRes>(`tasks/${id}/${status}`);
  }

  updateTask(id: number, payload: Partial<ITaskData>) {
    return http.patch<ITaskRes>(`tasks/${id}`, payload);
  }

  deleteTask(id: number) {
    return http.delete<ITaskRes>(`tasks/${id}`);
  }

  createPredefinedGoal(payload: IPredefinedGoalResponse) {
    return http.post<IPredefinedGoalResponse>('goals/predefined', payload);
  }

  archiveGoal(id: number | string) {
    return http.post<IPredefinedGoalResponse>(`goals/${id}/archive`);
  }

  markWithStarGoal(id: number | string) {
    return http.post<IGoal>(`goals/${id}/mark-with-star`);
  }

  removeStarMarkGoal(id: number | string) {
    return http.post<IGoal>(`goals/${id}/remove-star-mark`);
  }

  downloadGoals(statusParams: any, order?: Array<string[]>) {
    return http.get<any>('goals/download', {
      "params": {
        "filter": {
          "where": {
            "$or": statusParams
          },
          "order": order?.length && !EXPORT_BLACK_LIST.includes(order[0][0]) ? order : [ ["createdAt", "DESC"] ],
          "include": [
            {
              "association": "category"
            },
            {
              "association": "tasks"
            },
            {
              "association": "goalType"
            }
          ]
        },
        "fields": [
          {
            "source": "title",
            "name": "Goal Title"
          },
          {
            "source": "category.name",
            "name": "Category"
          },
          {
            "source": "type",
            "name": "Goal Type"
          },
          {
            "source": "tasks",
            "name": "Task",
            "subfieldsStrategy": "columns",
            "subfields": [
              {
                "source": "title",
                "name": "Title"
              },
              {
                "source": "description",
                "name": "Description"
              },
              {
                "source": "targetDate",
                "name": "Target Date"
              }
            ]
          }
        ]
      }
    });
  }
}

export const myGoalsService = new MyGoalsService();
