import { RouteRecordRaw } from 'vue-router';

export const authRoutesNames = {
  login: 'Login',
  signup: 'SignUp',
  greeting: 'Greeting',
  forgotPassword: 'ForgotPassword',
  confirmForgotPassword: 'ConfirmForgotPassword',
  createNewPassword: 'CreateNewPassword'
};

export const authRoutes: Array<RouteRecordRaw> = [
  {
    path: 'login',
    name: authRoutesNames.login,
    component: () => import('./Login.vue')
  },
  {
    path: 'signup',
    name: authRoutesNames.signup,
    component: () => import('./SignUp.vue')
  },
  {
    path: 'greeting',
    name: authRoutesNames.greeting,
    component: () => import('./Greeting.vue')
  },
  {
    path: 'forgot-password',
    name: authRoutesNames.forgotPassword,
    component: () => import('./ForgotPassword.vue')
  },
  {
    path: 'confirm-forgot-password',
    name: authRoutesNames.confirmForgotPassword,
    component: () => import('./ConfirmForgotPassword.vue')
  }
  ,
  {
    path: 'create-new-passowrd',
    name: authRoutesNames.createNewPassword,
    component: () => import('./CreateNewPassword.vue')
  }
];