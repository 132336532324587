import { playgroundRoutesNames } from '@/playground/playground.routes';
import { authRoutesNames } from '@/views/auth/auth.routes';
import { homeRoutesNames } from '@/views/home/home.routes';
import { childrenRoutesNames } from '@/views/children/children.routes';
import { userOptionsRoutesNames } from '@/views/user-options/user-options.routes';
import { userProfileRoutesNames } from '@/views/user-profile/user-profile.routes';
import { adminRoutesNames } from '@/views/admin/admin.routes';


export const routesNames = {
  root: 'Root',
  default: 'Default',
  auth: 'Auth',
  admin: 'Admin',

  ...playgroundRoutesNames,
  ...authRoutesNames,
  ...homeRoutesNames,
  ...childrenRoutesNames,
  ...userProfileRoutesNames,
  ...userOptionsRoutesNames,
  ...adminRoutesNames
};