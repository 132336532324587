
import { defineComponent } from 'vue';

import AdminSidebar from '@/components/navbars/AdminSidebar.vue';

export default defineComponent({
  name: 'Admin',

  components: { AdminSidebar },

});
