import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { authService } from '@/services';
import { routesNames } from '@/router';
import { vuex } from '@/store';
const whiteList = ['/auth'];

export const mainRouteGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  if (whiteList.some(el => to.fullPath.startsWith(el))) {
    next();
  } else if (!await authService.getAuthenticatedUser()) { 
    next({ name: routesNames.login });
  } else {
    next();
  }
};

export const playgroundGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  if (process.env.NODE_ENV === 'development') {
    next();
  } else {
    next(false);
  }
};

export const adminBeforeEnterGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  if (vuex.isAdmin) {
    next();
  } else {
    const redirectName = from.name || routesNames.root;
    next({ name: redirectName });
  }
};

export const familyBeforeEnterGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  if (vuex.isAdmin) {
    const redirectName = from.name || routesNames.adminFamilies;
    next({ name: redirectName });
  } else {
    next();
  }
};


