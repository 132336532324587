import { 
  createRouter, 
  createWebHistory, 
  RouteRecordRaw, 
  onBeforeRouteUpdate, 
  useRoute 
} from 'vue-router';

import { routesNames } from './route-names';
import { 
  mainRouteGuard,
  adminBeforeEnterGuard,
  familyBeforeEnterGuard,
  playgroundGuard 
} from './route-guards';

import { authRoutes } from '@/views/auth/auth.routes';
import { homeRoutes } from '@/views/home/home.routes';
import { userOptionsRoutes } from '@/views/user-options/user-options.routes';
import { userProfileRoutes } from '@/views/user-profile/user-profile.routes';
import { childrenRoutes } from '@/views/children/children.routes';
import { adminRoutes } from '@/views/admin/admin.routes';
import { playgroundRoutes } from '@/playground/playground.routes';

import Default from '@/layouts/Default.vue';
import Auth from '@/layouts/Auth.vue';
import Admin from '@/layouts/Admin.vue';
import Playground from '@/playground/Playground.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: routesNames.root,
    redirect: { name: routesNames.home },
    component: Default,
    children: [
      ...homeRoutes,
      ...childrenRoutes,
      ...userOptionsRoutes,
      ...userProfileRoutes
    ],
    beforeEnter: familyBeforeEnterGuard
  },
  {
    path: '/auth',
    name: routesNames.auth,
    redirect: { name: routesNames.login },
    component: Auth,
    children: authRoutes
  },
  {
    path: '/admin',
    name: routesNames.admin,
    redirect: { name: routesNames.adminFamilies },
    component: Admin,
    children: adminRoutes,
    beforeEnter: adminBeforeEnterGuard
  },
  {
    path: '/playground',
    name: 'Playground',
    component: Playground,
    children: playgroundRoutes,
    beforeEnter: playgroundGuard
  },
  // TODO add 404 page
  { path: '/:pathMatch(.*)*', 
    component: Default,
    redirect: { name: routesNames.home }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(mainRouteGuard);

export {
  routesNames,
  routes,
  router,
  onBeforeRouteUpdate,
  useRoute
};