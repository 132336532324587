<template>
  <div class="flex flex-col h-full min-h-screen">
    <!-- N A V B A R -->
    <div class="justify-between items-center flex-0 p-20">
      <div class="flex items-center">
        <img src="@/assets/images/CEB-logo.png" alt="Partnership logo" class="h-44">
      </div>
    </div>

    <div class="content flex flex-1">
      <!-- S I D E B A R -->
      <div class="relative left-0 bottom-0 top-0">
        <AdminSidebar />
      </div>

      <!-- V I E W S -->
      <div class="views-wrap container-xl h-full">
        <router-view />
      </div>
    </div>
  </div>

  <!-- V U E  P O R T A L -->
  <portal-target name="admin" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AdminSidebar from '@/components/navbars/AdminSidebar.vue';

export default defineComponent({
  name: 'Admin',

  components: { AdminSidebar },

});
</script>

<style lang="scss" scoped>
.content {
  height: calc(100% - 84px);
}

.views-wrap {
  width: calc(100% - 280px);
}
</style>