<template>
  <div class="container-xl justify-between items-center">
    <div class="flex flex-col">
      <div class="flex items-center">
        <img 
          src="@/assets/images/CEB-logo.png" 
          alt="logo" 
          class="h-68 cursor-pointer"
          @click="router.push({ name: routesNames.root })"
        >
      </div>
    </div>

    <!-- L I N K S -->
    <div class="flex items-center">
      <div
        v-for="(link, index) of items" 
        :key="index"
        class="mr-30 lg:mr-50"
      >
        <!-- D R O P D O W N -->
        <v-dropdown
          v-if="link.sublinks"
          :disabled="!link.sublinks"
          placement="bottom"
          :offset="[0, 2]"
          @hide="hideSubmenu"
          @show="openSubmenu"
        >
          <div class="relative flex items-center justify-between link text-xl lg:text-1xl text-dark-cl-20">
            <p class="cursor-pointer py-44">{{ link.name }}</p>
            <AppIcon 
              v-if="link.sublinks"
              name="arrow-close"
              size="24"
              class="transform transition duration-300 z-1"
              :class="isOpenSublinkMenu ? 'rotate-180' : 'rotate-0'"
            />
          </div>

          <!-- P O P P E R -->
          <template #popper>
            <div class="min-w-140 py-12">
              <template 
                v-for="(sublink, si) of link.sublinks"
                :key="si"
              >
                <component 
                  :is="sublink.disabled ? 'span': 'router-link'"
                  v-if="!sublink.hidden" 
                  v-close-popper="!sublink.disabled"
                  :to="{ name: sublink.pathName }"
                  class="sublink block mb-3"
                >
                  <p 
                    class="text-dark-cl-20 cursor-pointer py-10 px-16 hover:bg-blue-fp-40" 
                    :class="{ 'opacity-30 bg-blue-fp-40 cursor-not-allowed': sublink.disabled }"
                  >
                    {{ sublink.name }}
                  </p>
                </component>
              </template>
            </div>
          </template>
        </v-dropdown>

        <template v-else>
          <component 
            :is="link.externalLink ? 'a': 'router-link'"
            :to="{ name: link.pathName }" 
            :disabled="link.disabled" 
            :target="link.externalLink && '_blank'"
            :href="link.externalLink"
            class="link text-xl lg:text-1xl text-dark-cl-20 flex items-center"
          >
            <p class="py-44">{{ link.name }}</p>
          </component>
        </template>
      </div>
        
      <!-- D R O P D O W N -->
      <v-dropdown
        :shown="isOpenUserOptions"
        :offset="[0, 30]"
        :triggers="[]"
        placement="bottom-start"
        @hide="hideUserOptions"
      >
        <!-- T R I G G E R  -->
        <div class="relative flex items-center justify-center" @click="openUserOptions">
          <AppUserAvatar :username="userFullName" class="h-60 w-60 text-1xl mr-10 cursor-pointer" />
          <AppIcon 
            name="arrow-close"
            class="transform transition duration-300 z-1" 
            size="24"
            :class="isOpenUserOptions ? 'rotate-180' : 'rotate-0'"
          />
        </div>

        <!-- P O P P E R -->
        <template #popper>
          <div class="min-w-140 py-12">
            <p 
              class="text-dark-cl-20 cursor-pointer py-10 px-16 hover:bg-blue-fp-40" 
              @click="onUserProfile"
            >
              My profile
            </p>
            <p 
              class="text-dark-cl-20 cursor-pointer py-10 px-16 hover:bg-blue-fp-40" 
              @click="$emit('log-out')"
            >
              Logout
            </p>
          </div>
        </template>
      </v-dropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';

import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';
import AppIcon from '@/components/stateless/AppIcon.vue';

import { INavbarItem } from '@/types';
import { router, routesNames } from '@/router';

export default defineComponent({
  name: 'FamilyPortalDesktopNavbar',

  components: { AppUserAvatar, AppIcon },

  props: {
    items: {
      type: Array as PropType<INavbarItem[]>,
      required: true
    },
    
    userFullName: {
      type: String,
      required: true
    }
  },

  emits: ['log-out'],

  setup() {
    let isOpenSublinkMenu = ref<boolean>(false);
    let isOpenUserOptions = ref<boolean>(false);

    function openSubmenu () { isOpenSublinkMenu.value = true; }
    function hideSubmenu () { isOpenSublinkMenu.value = false; }

    function openUserOptions () { isOpenUserOptions.value = true; }
    function hideUserOptions () { isOpenUserOptions.value = false; }

    function onUserProfile () {
        router.push({ name: routesNames.userProfile });
        hideUserOptions();
    }

    return { 
      isOpenSublinkMenu, 
      isOpenUserOptions,
      router,
      routesNames,

      openSubmenu,
      hideSubmenu,
      openUserOptions, 
      hideUserOptions,
      onUserProfile
    };
  }
});
</script>

<style lang="scss" scoped>
.router-link-active {

  &:not(.sublink) {
    @apply relative;
    &::after {
      @apply bg-primary w-full h-3 absolute left-0 bottom-0;
      content: '',
    }
  }

  &.sublink {
    @apply bg-blue-fp-40;
  }
}
</style>ß

