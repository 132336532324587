
import { defineComponent, ref, PropType } from 'vue';

import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';
import AppIcon from '@/components/stateless/AppIcon.vue';

import { INavbarItem } from '@/types';
import { router, routesNames } from '@/router';

export default defineComponent({
  name: 'FamilyPortalDesktopNavbar',

  components: { AppUserAvatar, AppIcon },

  props: {
    items: {
      type: Array as PropType<INavbarItem[]>,
      required: true
    },
    
    userFullName: {
      type: String,
      required: true
    }
  },

  emits: ['log-out'],

  setup() {
    let isOpenSublinkMenu = ref<boolean>(false);
    let isOpenUserOptions = ref<boolean>(false);

    function openSubmenu () { isOpenSublinkMenu.value = true; }
    function hideSubmenu () { isOpenSublinkMenu.value = false; }

    function openUserOptions () { isOpenUserOptions.value = true; }
    function hideUserOptions () { isOpenUserOptions.value = false; }

    function onUserProfile () {
        router.push({ name: routesNames.userProfile });
        hideUserOptions();
    }

    return { 
      isOpenSublinkMenu, 
      isOpenUserOptions,
      router,
      routesNames,

      openSubmenu,
      hideSubmenu,
      openUserOptions, 
      hideUserOptions,
      onUserProfile
    };
  }
});
