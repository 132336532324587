import { App } from 'vue';
import dayjs from 'dayjs';

export const date = (value: string | Date, format = 'MMM D, YYYY h:mm A'): string => {
  return dayjs(value).format(format);
};

const millisInDecisecond = 100;
const millisInSecond = millisInDecisecond * 10;
const millisInMinute = millisInSecond * 60;
const millisInHour = millisInMinute * 60;
const millisInDay = millisInHour * 24;
const millisInMonth = millisInDay * 30;

export const timeMillisToTimeAgo = (previous: Date): string => {
  const current = Date.now();
  const previousDate = new Date(previous) as any;
  const elapsed = current - previousDate;
  const todayHours = new Date().getHours();

  if (elapsed < millisInMinute) {
    // Between now and 1 minute
    const seconds = Math.round(elapsed / 1000);
    return seconds < 10 ? 'just now' : `${seconds} seconds ago`;
  } else if (elapsed < millisInHour) {
    // Between 1 minute and 1 hour
    const mins = Math.round(elapsed / millisInMinute);
    return (mins === 1) ? `${mins} minute ago` : `${mins} minutes ago`;
  } else if (elapsed < millisInDay && elapsed <= millisInHour * 3) {
    // Between 1 hour and 3 hours
    const hours = Math.round(elapsed / millisInHour);
    return (hours === 1) ? `${hours} hour ago` : `${hours} hours ago`;
  } else if (elapsed < millisInDay && elapsed >= millisInHour * 3 && elapsed - todayHours * millisInHour <= 0) {
    // Between 3 hours and 1 day
    return dayjs(previousDate).format('h:mm a');
  } else if (elapsed < millisInMonth && elapsed <= todayHours * millisInHour + millisInDay) {
    // Yesterday
    return `Yesterday at ${dayjs(previousDate).format('h:mm a')}`;
  } else {
    // Before yesterday
    return `${dayjs(previousDate).format('D MMMM')} at ${dayjs(previousDate).format('h:mm a')}`;
  }
};

export const filters = {
  install: (app: App): void => {
    app.config.globalProperties.$filters = {
      date,
      timeMillisToTimeAgo
    };
  }
};
