<template>
  <div>
    <!-- N A V B A R -->
    <AppFamilyPortalNavbar />

    <!-- V I E W S -->
    <div class="container-xl w-full h-full pt-70 xs:pt-90 md:pt-25 pb-25">
      <router-view />
    </div>
  </div>

  <!-- V U E  P O R T A L -->
  <portal-target name="default" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppFamilyPortalNavbar from '@/components/navbars/AppFamilyPortalNavbar.vue';

export default defineComponent({
  name: 'Default',

  components: { AppFamilyPortalNavbar },

});
</script>