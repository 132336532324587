import { http } from '@/services';
import { IActivity, IActivityForm, TIndexedObject, IRequestParams, IActivityResponse } from '@/types';

class ActivitiesService {

  createActivity(payload: IActivity) {
    return http.post<IActivity>('activities', payload);
  }

  fetchActivities(params?: IRequestParams) {
    return http.get<IActivityResponse>('activities', params);
  }

  updateActivity(id: number | string, payload: Partial<IActivityForm>) {
    return http.patch<IActivity>(`activities/${id}`, payload);
  }

  fetchActivity(id: number | string, params?: IRequestParams) {
    return http.get<IActivity>(`activities/${id}`, params);
  }

  removeActivity(id: number) {
    return http.post<IActivity>(`activities/${id}/cancel`);
  }

  registerForActivity(id: number) {
    return http.post<IActivity>(`activities/${id}/register`);
  }

  cancelRegistration(id: number) {
    return http.post<IActivity>(`registrations/${id}/cancel`);
  }

  confirmRegistration(id: number) {
    return http.post<IActivity>(`registrations/${id}/confirm-registration`);
  }

  discardRegistration(id: number) {
    return http.post<IActivity>(`registrations/${id}/discard-registration`);
  }

  confirmCancellation(id: number) {
    return http.post<IActivity>(`registrations/${id}/confirm-cancellation`);
  }

  discardCancellation(id: number) {
    return http.post<IActivity>(`registrations/${id}/discard-cancellation`);
  }

  registrationCount(params?: TIndexedObject) {
    return http.get<{ count: number }>('registrations/count', params);
  }

  fetchRegistrations(params?: TIndexedObject) {
    return http.get<IActivity>('registrations', params);
  }

  fetchRegistration(id: number, params?: TIndexedObject) {
    return http.get<IActivity>(`registrations/${id}`, params);
  }

  registerChildForActivity(id: number, childId: number) {
    return http.post<IActivity>(`activities/${id}/register`, { childId });
  }

  downloadAttendees(id: string | number) {
    return http.get<any>('activities/download', {
      params: {
        "filter": {
          "where": {
            "id": id
          },
          "include": [
            {
              "association": "attendees"
            }
          ]
        },
        "fields": [
          {
            "source": "attendees",
            "name": "Attendee",
            "subfieldsStrategy": "rows",
            "subfields": [
              {
                "source": "firstName",
                "name": "First Name"
              },
              {
                "source": "lastName",
                "name": "Last Name"
              }
            ]
          }
        ]
      }
    });
  }

  downloadActivities(order?: Array<string[]>) {
    return http.get<any>('activities/download', {
      "params": {
        "filter": {
          order: order?.length ? order : [ ["createdAt", "DESC"] ],
          where: {
            status: 'active',
            endDate: { $gt: new Date().toISOString() }
          },
          "include": [
            {
              "association": "category"
            }
          ]
        },
        "fields": [
          {
            "name": "Activity",
            "source": "name"
          },
          {
            "name": "Category",
            "source": "category.name"
          },
          {
            "name": "Start Date",
            "source": "startDate"
          },
          {
            "name": "End Date",
            "source": "endDate"
          }
        ]
      }
    });
  }

}

export const activitiesService = new ActivitiesService();