
import { defineComponent, onBeforeMount, ref } from 'vue';
import { vuex } from '@/store';
import { authService } from '@/services';

export default defineComponent({
  name: 'App',

  setup() {
    const hasUpdatedUser = ref<boolean>(false);

    onBeforeMount( async () => {
      await authService.getAuthenticatedUser(true);
      hasUpdatedUser.value = true;
    });
    return { vuex, hasUpdatedUser };
  }
});
