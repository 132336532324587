import { RouteRecordRaw } from 'vue-router';

export const adminRoutesNames = {
  adminActivities: 'AdminActivities',
  adminActivitiesList: 'AdminActivitiesList',
  adminAddActivities: 'AdminAddActivities',
  adminUpdateActivities: 'AdminUpdateActivities',
  adminFamilies: 'AdminFamilies',
  adminNotifications: 'AdminNotifications',
  adminNotificationsList: 'AdminNotificationsList',
  adminNotificationsCreate: 'AdminNotificationsCreate',
  adminNotificationsUpdate: 'AdminNotificationsUpdate',
  adminFamiliesList: 'AdminFamiliesList',
  adminUpdateFamily: 'AdminUpdateFamily',
  adminGoalsList: 'AdminGoalsList',
  adminAddGoal: 'AdminAddGoal',
  adminUpdateGoal: 'AdminUpdateGoal',
  adminGoals: 'AdminGoals',
  adminSettings: 'AdminSettings',
  adminDashboard: 'AdminDashboard',
  adminSchools: 'AdminSchools',
  adminCategories: 'AdminCategories',
  admins: 'Admins',
  adminAddAdmin: 'AdminAddAdmin'
};

export const adminRoutes: Array<RouteRecordRaw> = [
  {
    path: 'activities',
    name: adminRoutesNames.adminActivities,
    redirect: { name: adminRoutesNames.adminActivitiesList },
    component: () => import('./activities/index.vue'),
    children: [
      {
        path: 'upcoming',
        name: adminRoutesNames.adminActivitiesList,
        component: () => import('./activities/ActivitiesList.vue'),
      },
      {
        path: 'update',
        name: adminRoutesNames.adminUpdateActivities,
        component: () => import('./activities/UpdateActivity.vue'),
      },
      {
        path: 'add',
        name: adminRoutesNames.adminAddActivities,
        component: () => import('./activities/AddActivity.vue'),
      },
    ]
  },
  {
    path: 'families',
    name: adminRoutesNames.adminFamilies,
    redirect: { name: adminRoutesNames.adminFamiliesList },
    component: () => import('./families/index.vue'),
    children: [
      {
        path: 'list',
        name: adminRoutesNames.adminFamiliesList,
        component: () => import('./families/FamiliesList.vue')
      },
      {
        path: 'update',
        name: adminRoutesNames.adminUpdateFamily,
        component: () => import('./families/UpdateFamily.vue')
      },
    ]
  },
  {
    path: 'notifications',
    name: adminRoutesNames.adminNotifications,
    redirect: { name: adminRoutesNames.adminNotificationsList },
    component: () => import('./notifications/index.vue'),
    children: [
      {
        path: 'list',
        name: adminRoutesNames.adminNotificationsList,
        component: () => import('./notifications/NotificationsList.vue')
      },
      {
        path: 'create',
        name: adminRoutesNames.adminNotificationsCreate,
        component: () => import('./notifications/CreateNotification.vue')
      },
      {
        path: 'update',
        name: adminRoutesNames.adminNotificationsUpdate,
        component: () => import('./notifications/UpdateNotification.vue')
      }
    ]
  },
  {
    path: 'goals',
    name: adminRoutesNames.adminGoals,
    redirect: { name: adminRoutesNames.adminGoalsList },
    component: () => import('./goals/index.vue'),
    children: [
      {
        path: 'list',
        name: adminRoutesNames.adminGoalsList,
        component: () => import('./goals/GoalsList.vue')
      },
      {
        path: 'addGoal',
        name: adminRoutesNames.adminAddGoal,
        component: () => import('./goals/AddGoal.vue')
      },
      {
        path: 'updateGoal',
        name: adminRoutesNames.adminUpdateGoal,
        component: () => import('./goals/UpdateGoal.vue')
      },
    ]
  },
  {
    path: 'settings',
    name: adminRoutesNames.adminSettings,
    component: () => import('./settings/index.vue'),
    redirect: { name: adminRoutesNames.adminDashboard },
    children: [
      {
        path: 'dashboard',
        name: adminRoutesNames.adminDashboard,
        component: () => import('./settings/Dashboard.vue')
      },
      {
        path: 'schools',
        name: adminRoutesNames.adminSchools,
        component: () => import('./settings/Schools.vue')
      },
      {
        path: 'categories',
        name: adminRoutesNames.adminCategories,
        component: () => import('./settings/Categories.vue')
      },
      {
        path: 'admins',
        name: adminRoutesNames.admins,
        component: () => import('./settings/Admins.vue')
      },
      {
        path: 'add-admin',
        name: adminRoutesNames.adminAddAdmin,
        component: () => import('./settings/AddAdmin.vue')
      }
    ]
  },
];