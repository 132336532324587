<template>
  <div class="max-w-800 mx-auto px-20">
    <!-- Logos -->
    <div class="flex items-center pt-40 mb-30">
      <img
        src="@/assets/images/CEB-logo.png"
        alt="Logo"
        class="h-77"
      >
    </div>

    <!-- Views -->
    <router-view />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, onBeforeMount } from 'vue';
  import { authService } from '@/services';

  export default defineComponent({
    name: 'Auth',

    setup() {
      let partnerName = ref<string>('CEB'); // TODO should be dynamic

      onBeforeMount(async () => {
        await authService.signOut();
      });

      return { partnerName };
    }
  });
</script>