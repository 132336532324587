class ValidatioinDatesService {

  isDate (value: any) {
    return !isNaN(Date.parse(value));
  }

  validateStartEndDate (date1: any, date2: any) {
    if (this.isDate(date1) && this.isDate(date2)) {
      const startDate = new Date(date1);
      const endDate = new Date(date2);

      return startDate.getTime() < endDate.getTime();
    }

    return true;
  }
}

export const validatioinDatesService = new ValidatioinDatesService();