import { http } from '@/services';
import { IAdminUserForm } from '@/types';

class AdminUsersService {

  fetchAdmins(params?: any) {
    return http.get<any>('user-identities/admins', params);
  }

  createAdmin(payload?: IAdminUserForm) {
    return http.post<any>('user-identities/admins', payload);
  }
}

export const adminUsersService = new AdminUsersService();
