
import { defineComponent, ref } from 'vue';
import { routesNames, router } from '@/router';

import AppIcon from '@/components/stateless/AppIcon.vue';

export default defineComponent({
  name: 'AdminSidebar',

  components: { AppIcon },

  setup() {
    const linkItems = [
      { pathName: routesNames.adminFamilies, linkName: 'Families', icon: 'families' },
      { pathName: routesNames.adminNotificationsList, linkName: 'Notifications', icon: 'notifications', },
      { pathName: routesNames.adminActivities, linkName: 'Activities', icon: 'calendar' },
      { pathName: routesNames.adminGoals, linkName: 'Goals', icon: 'goals' },
      { pathName: routesNames.adminSettings, linkName: 'Settings', icon: 'settings' }
    ];

    let isCollapsed = ref<boolean>(false);

    function handleCollapse () {
      isCollapsed.value = !isCollapsed.value;
    }

    function handleLogOut () {
      router.push({ name: routesNames.login });
    }

    return {
      linkItems,
      isCollapsed,

      handleCollapse,
      handleLogOut
    };
  }
});
