import { http } from '@/services';
import { IFamilyAttributes, IRequestParams, IFamilyRes, IFamilyData } from '@/types';


class FamiliesService {

  fetchFamilies(params?: any) {
    return http.get<IFamilyRes>('families', params);
  }

  fetchFamily(id: string, params?: IRequestParams) {
    return http.get<IFamilyData>(`families/${id}`, params);
  }

  updateFamily(id: string, payload: IFamilyAttributes) {
    return http.patch<IFamilyRes>(`families/${id}/attributes`, payload);
  }

  downloadFamilies() {
    return http.get<any>('families/download', {
      params: {
        "fields": [
          {
            "name": "First Name",
            "source": "details.attributes.firstName"
          },
          {
            "name": "Last Name",
            "source": "details.attributes.lastName"
          },
          {
            "name": "Email",
            "source": "details.attributes.email"
          },
          {
            "name": "Phone Number",
            "source": "details.attributes.phoneNumber"
          },
          {
            "name": "User ID",
            "source": "details.attributes.charityTrackingId"
          },
          {
            "name": "Last Seen Date",
            "source": "lastSeenDate"
          }
        ]
      }
    });
  }
}

export const familiesService = new FamiliesService();
