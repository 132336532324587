<template>
  <div class="mobile-menu relative">
    <!-- H E A D E R -->
    <div class="fixed bg-white shadow-1 flex items-center h-50 xs:h-70 w-full px-20 py-17 z-50">
      <div
        class="flex items-center"
        @click="handleOpenNav"
      >
        <AppIcon
          :name="isOpenMenu ? 'close' : 'burger'"
          :size="isOpenMenu ? 16 : 24"
          class="text-grey-fp-80"
        />

        <span
          v-if="!isOpenMenu"
          class="text-grey-fp-80 text-md"
        >Menu</span>
      </div>
    </div>

    <!-- S I D E B A R -->
    <div
      :class="[isOpenMenu ? 'visible': 'invisible']"
      class="fixed top-50 xs:top-70 left-0 bottom-0 w-full z-10"
    >
      <!-- O V E R L A Y -->
      <div
        :class="[isOpenMenu ? 'opacity-60': 'opacity-10']"
        class="overlay absolute top-0 left-0 right-0 bottom-0 bg-dark-cl-20"
        @click="handleOpenNav"
      />
      <!-- M E N U  I T E M S -->
      <div
        class="menu-items-wrap"
        :class="[isOpenMenu ? 'left-0' : '-left-280 xs:-left-320 ']"
      >
        <div>
          <div class="flex items-center h-80 px-22 xs:px-28 border-b border-grey-fp-30 py-18">
            <AppUserAvatar
              :username="userFullName"
              class="min-h-45 min-w-45 text-xl mr-10"
            />
            <span class="text-grey-fp-80 text-md truncate">{{ userFullName }}</span>
          </div>

          <!-- L I N K S -->
          <ul class="px-22 xs:px-28 border-b border-grey-fp-30">
            <li
              v-for="(link, i) of items"
              :key="i"
            >
              <component
                :is="link.externalLink ? 'a': 'router-link'"
                v-if="!link.sublinks"
                :to="{ name: link.pathName }"
                :disabled="link.disabled"
                :target="link.externalLink && '_blank'"
                :href="link.externalLink"
                class="mobile-link text-md text-dark-cl-20"
              >
                <div
                  class="flex items-center justify-between my-18 hover:text-primary"
                  @click="handleOpenNav"
                >
                  <p class="truncate">{{ link.name }}</p>
                </div>
              </component>

              <div
                v-else
                class="flex items-center justify-between my-18 cursor-pointer hover:text-primary text-md"
                @click="toggleSubmenu"
              >
                <p class="truncate">{{ link.name }}</p>
                <AppIcon
                  v-if="link.sublinks"
                  name="arrow-close"
                  size="20"
                  class="submenu-icon cursor-pointer transform"
                  :class="isOpenSublinkMenu ? 'rotate-180' : 'rotate-0'"
                />
              </div>

              <!-- S U B L I N K S -->
              <ul
                v-if="isOpenSublinkMenu"
                class="px-10"
              >
                <template
                  v-for="(sublink, si) of link.sublinks"
                  :key="si"
                >
                  <component
                    :is="sublink.disabled ? 'span': 'router-link'"
                    v-if="!sublink.hidden"
                    :to="{ name: sublink.pathName }"
                    class="mobile-link text-dark-cl-20 flex items-center justify-between mb-18 last:mb-0 "
                    :class="[ sublink.disabled ? 'opacity-30': 'hover:text-primary']"
                  >
                    <p
                      class="truncate text-base"
                      @click="() => !sublink.disabled ? handleOpenNav(): null"
                    >
                      {{ sublink.name }}
                    </p>
                  </component>
                </template>
              </ul>
            </li>
          </ul>

          <div class="py-18 px-22 xs:px-28">
            <p
              class="text-dark-cl-20 text-md hover:text-primary cursor-pointer mb-18"
              @click="onUserProfile"
            >
              User Profile
            </p>
            <p
              class="text-dark-cl-20 text-md hover:text-primary cursor-pointer"
              @click="$emit('log-out')"
            >
              Logout
            </p>
          </div>
        </div>

        <div class="flex flex-col p-22">
          <div class="flex items-center">
            <img
              src="@/assets/images/CEB-logo.png"
              alt="logo"
              class="h-48"
              @click="router.push({ name: routesNames.root })"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, PropType } from 'vue';

  import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { INavbarItem } from '@/types';
  import { router, routesNames } from '@/router';

  export default defineComponent({
    name: 'FamilyPortalSidebar',

    components: { AppUserAvatar, AppIcon },

    props: {
      items: {
        type: Array as PropType<INavbarItem[]>,
        required: true
      },

      userFullName: {
        type: String,
        required: true
      }
    },

    emits: ['log-out', 'user-profile'],

    setup() {
      let isOpenMenu = ref<boolean>(false);
      let isOpenSublinkMenu = ref<boolean>(false);

      function handleOpenNav() {
        if (isOpenMenu.value) {
          document.body.style.overflow = 'auto';
          isOpenMenu.value = false;
          isOpenSublinkMenu.value = false;
        } else {
          document.body.style.overflow = 'hidden';
          isOpenMenu.value = true;
        }
      }

      function toggleSubmenu() { isOpenSublinkMenu.value = !isOpenSublinkMenu.value; }

      function onUserProfile () {
        router.push({ name: routesNames.userProfile });
        handleOpenNav();
    }

      return {
        isOpenMenu,
        isOpenSublinkMenu,
        router,
        routesNames,

        handleOpenNav,
        toggleSubmenu,
        onUserProfile
      };
    }
  });
</script>

<style lang="scss" scoped>
  .router-link-active {
    &.mobile-link {
      @apply text-primary;
    }
  }
  .mobile-menu {
    .menu-items-wrap,
    .overlay {
      transition: all 0.2s;
    }

    .menu-items-wrap {
      @apply absolute bottom-0 top-0 bg-white w-280 xs:w-320 overflow-y-auto flex flex-col justify-between;
    }
  }

  .submenu-icon {
    @apply transition duration-300;
  }
</style>

