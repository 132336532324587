import { http } from '@/services';
import { ICategoryRes, IRequestParams, ICategory, ICategoryForm } from '@/types';

class CategoriesService {

  fetchCategories(params?: IRequestParams) {
    return http.get<ICategoryRes>('categories', {
      params:{ 
        ...params?.params, 
        includeCount: true
      },
    } as IRequestParams);
  }

  createCategory(payload: ICategoryForm) {
    return http.post<ICategory>('categories', payload);
  }

  updateCategory(id: string | number, payload: ICategoryForm) {
    return http.patch<ICategory>(`categories/${id}`, payload);
  }

  archiveCategory(id: string | number) {
    return http.post<ICategory>(`categories/${id}/archive`);
  }

  unarchiveCategory(id: string | number) {
    return http.post<ICategory>(`categories/${id}/unarchive`);
  }
}

export const categoriesService = new CategoriesService();
