import { http } from '@/services';
import { IProfile } from '@/types';

class ProfileService {
  fetchProfile() {
    return http.get<IProfile>('profile');
  }

  updateProfile(payload: IProfile) {
    return http.patch<IProfile>('profile', payload);
  }

}

export const profileService = new ProfileService();