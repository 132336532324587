
import { isUserKeptSignedIn } from '@/services';

class AuthTokenStorage {
  
  setItem(key: string, value: string): void {
    if (isUserKeptSignedIn()) {
      localStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

   getItem(key: string): string | null {
    if (isUserKeptSignedIn()) {
      return localStorage.getItem(key);
    } else {
      return sessionStorage.getItem(key);
    }
  }

   removeItem(key: string): void {
    if (isUserKeptSignedIn()) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  clear(): void {
    if (isUserKeptSignedIn()) {
      localStorage.clear();
    } else {
      sessionStorage.clear();
    }
  }
}

export const authTokenStorage = new AuthTokenStorage();