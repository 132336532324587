<template>
  <!-- P A G E  L O A D I N G  I N D I C A T O R -->
  <div 
    v-if="vuex.isLoading" 
    class="fixed bg-white opacity-60 top-0 left-0 h-full w-full flex items-center justify-center z-1000"
  >
    <div class="loader" />
  </div>

  <router-view v-if="hasUpdatedUser" />
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue';
import { vuex } from '@/store';
import { authService } from '@/services';

export default defineComponent({
  name: 'App',

  setup() {
    const hasUpdatedUser = ref<boolean>(false);

    onBeforeMount( async () => {
      await authService.getAuthenticatedUser(true);
      hasUpdatedUser.value = true;
    });
    return { vuex, hasUpdatedUser };
  }
});
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/scss/components/_loader";
</style>