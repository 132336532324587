import { http } from '@/services';
import { IChild } from '@/types';

class ChildrenService {

  fetchChildren() {
    return http.get<IChild[]>('children', {
      params: { 
        filter: { 
          include: [
            { 
              model: "school",
              attributes: ["id", "name", "quickLinks", "socialLinks"]
            }
          ]
        } 
      }
    });
  }

  createChild(payload: IChild) {
    return http.post<IChild>('children', payload);
  }

  deleteChildren() {
    return http.delete<IChild>('children');
  }

  fetchChild(id: string) {
    return http.get<IChild>(`children/${id}`, {
      params: { 
        filter: { 
          include: [
            { 
              model: "school",
            }
          ]
        } 
      }
    });
  }

  updateChild(id: string, payload: Partial<IChild>) {
    return http.patch<Partial<IChild>>(`children/${id}`, payload);
  }

  removeChild(id: string) {
    return http.delete<IChild>(`children/${id}`);
  }
}

export const childrenService = new ChildrenService();
