import { http } from '@/services';
import { ISchool, ISchoolRes, ISchoolForm, IRequestParams} from '@/types';

class SchoolService {

  fetchSchools(params?: IRequestParams) {
    return http.get<ISchoolRes>('schools', params);
  }

  fetchSchool(id: string) {
    return http.get<ISchool>(`schools/${id}`);
  }

  fetchGrades() {
    return http.get<Array<string>>('schools/default-grades');
  }

  createSchool(payload: ISchoolForm) {
    return http.post<ISchool>('schools', payload);
  }

  updateSchool(id: string, payload: ISchoolForm) {
    return http.patch<ISchool>(`schools/${id}`, payload);
  }

  deleteSchool(id: string) {
    return http.delete<ISchool>(`schools/${id}`);
  }
}

export const schoolService = new SchoolService();