import { RouteRecordRaw } from 'vue-router';

export const userOptionsRoutesNames = {
  userOptions: 'UserOptions',
  exploreActivities: 'ExploreActivities',
  myGoals: 'MyGoals',
  myEssentials: 'MyEssentials',
  addGoal: "AddGoal",
  myGoal: "MyGoal"
};

export const userOptionsRoutes: Array<RouteRecordRaw> = [
  {
    path: 'user-options',
    name: userOptionsRoutesNames.userOptions,
    redirect: { name: userOptionsRoutesNames.myGoals },
    component: () => import ('./index.vue'),
    children: [
      {
        path: 'explore-activities',
        name: userOptionsRoutesNames.exploreActivities,
        component: () => import('./explore-activities/index.vue'),
      },
      {
        path: 'my-goals',
        name: userOptionsRoutesNames.myGoals,
        component: () => import('./my-goals/MyGoals.vue'),
      },
      {
        path: 'my-goals/add-goal',
        name: userOptionsRoutesNames.addGoal,
        component: () => import('./my-goals/AddGoal.vue'),
      },
      {
        path: 'my-goals/goal',
        name: userOptionsRoutesNames.myGoal,
        component: () => import('./my-goals/index.vue'),
      },
      {
        path: 'my-essentials',
        name: userOptionsRoutesNames.myEssentials,
        component: () => import('./my-essentials/index.vue'),
      }
    ]
  },
];